import React, { useState, useEffect } from 'react';
import MainNav from './components/Mainnav'
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import {
  Container, Row, Col, Alert,
  Navbar, Nav, NavDropdown, Form, FormControl, Button
} from 'react-bootstrap';
import Homepage from './pages/website/homepage'


function App() {

  return (
    <>
      <MainNav/>
      
      <Homepage/>


      {/* <Router>
        <Switch>
          <Route path="/about">
            About
          </Route>
          <Route path="/users">
            Users
          </Route>
          <Route path="/">
            <Homepage/>
          </Route>
        </Switch>
      </Router> */}

    </>
  );
}

export default App;
