import React, { useState, useEffect } from 'react';
import {Container, Row, Col, Carousel} from 'react-bootstrap';
// import axios from 'axios';
import circlePrimary from '../../images/circleYellow.svg';
import webAplication from '../../images/webAplication.png';
import mobileApp from '../../images/mobileApp.png';
import visualDesign from '../../images/visualDesign.png';
import logo from '../../images/logo.svg';
// Portfolio image
import lintas from '../../images/portfolio/lintas.png';
import batik from '../../images/portfolio/batik.png';
import gusami from '../../images/portfolio/gusami.png';
import eventByMe from '../../images/portfolio/eventByMe.png';
import orangProduk from '../../images/portfolio/orangProduk.png';
import kudaApp from '../../images/portfolio/kudaApp.png';


function Homepage() {
    return (
        <>
            {/* Header */}
            <div className="headerHome">
                <div className="content">
                    <h1>fqc <span>Push your limit!</span></h1>
                    <h4>Digital <br />Agency division</h4>
                </div>
                <div className="arrowDown"><i className="bi-arrow-down"></i></div>
            </div>
            {/* Header */}

            {/* Section About */}
            <div className="sectionAbout" id="who">
                <div className="item">
                    <div className="content">
                        <div className="contentGroup alignRight">
                            <h2>who we are and <br />what we do <span>Greeting :)</span></h2>
                            <p>We are a group of creative people who love to create useful work for others. we are part of Fajr Quran Center as a digital agency division. we were born to help raise FQC to an unimaginable point, so that the reach and benefits of this institution are wider and of higher quality.</p>
                        </div>
                    </div>
                    <div className="image">
                        <div className="imgAbout"></div>
                    </div>
                </div>
                <img src={circlePrimary} className="circlePrimary" />
            </div>
            {/* Section About */}

            {/* do something */}
            <div className="sectionDoSomething">
                <h2>Let's do something <br /> great!</h2>
            </div>
            {/* do something */}

            {/* Section Service product */}
            <Container className="sectionServiceProduct" id="what" fluid>
                <Row>
                    <Col md={4} sm={12} className="p-0">
                        <div className="item">
                            <img src={webAplication} />
                            <div className="content">
                                <h2>Web <br />Application</h2>
                                <p>We will create a website according to your needs, such as company profiles, e-commerce, or web applications for your business needs. tell us, what do you want.</p>
                            </div>
                            <button className="btn btn-primary btn-circle btn-lg btn-more-arrow" onClick={event => window.location.href = 'https://wa.me/6287884911473?text=Halo'}>Do it now <i className="bi-arrow-right"></i></button>
                        </div>
                    </Col>
                    <Col md={4} sm={12} className="p-0">
                        <div className="item">
                            <img src={mobileApp} />
                            <div className="content">
                                <h2>Mobile <br />Application</h2>
                                <p>We will make you a mobile application that looks cool and has amazing features, just what you need, tell us, what do you want.</p>
                            </div>
                            <button className="btn btn-primary btn-circle btn-lg btn-more-arrow" onClick={event => window.location.href = 'https://wa.me/6287884911473?text=Halo'}>Do it now <i className="bi-arrow-right"></i></button>
                        </div>
                    </Col>
                    <Col md={4} sm={12} className="p-0">
                        <div className="item">
                            <img src={visualDesign} />
                            <div className="content">
                                <h2>Visual <br />Design</h2>
                                <p>We make you a Branding design for your business needs, from logos, flyers, banners, product catalogs, brochures and whatever you need. so tell us what you want.</p>
                            </div>
                            <button className="btn btn-primary btn-circle btn-lg btn-more-arrow" onClick={event => window.location.href = 'https://wa.me/6287884911473?text=Halo'}>Do it now <i className="bi-arrow-right"></i></button>
                        </div>
                    </Col>
                </Row>
            </Container>
            {/* Section Service product */}


            {/* Why choose us */}
            <div className="sectionChooseUs" id="why">
                <Container>
                    <Row>
                        <Col>
                            <div className="contentGroup">
                                <h2>Experienced <br />helpfull and local. <span>Why choose us?</span></h2>
                                <p>Our team are experienced people, with high commitment and integrity, and last but not least, we are a native Indonesian startup who is ready to work both inside and outside the country.</p>
                                <br />
                                <button className="btn btn-primary btn-circle btn-lg btn-more-arrow mb-5">Do it now <i className="bi-arrow-right"></i></button>
                            </div>
                        </Col>
                        <Col>
                            <ul className="listStep">
                                <li>
                                    <span>1</span>
                                    <h5>Experienced People</h5>
                                    <p>The best and experienced people really determine the quality of the work itself, therefore, we are here to help and embrace you on the path of success.</p>
                                </li>
                                <li>
                                    <span>2</span>
                                    <h5>Local support</h5>
                                    <p>You don't have to worry if one day you get a problem, we are always there for you whenever you need, this commitment is our principle.</p>
                                </li>
                                <li>
                                    <span>3</span>
                                    <h5>High standard</h5>
                                    <p>Every work we create, we apply high standards for user interface, designing the best user experience, features and performance.</p>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Why choose us */}

            {/* JustTalk */}
            <div className="sectionJustTalk" id="price">
                <Container>
                    <Row>
                        <Col md={{ span: 6, offset: 6 }} xs={{ span: 12, offset: 0 }}>
                            <div className="contentGroup">
                                <h2>Just talk before <br />you decide</h2>
                                <p>You don't have to buy our services, whatever we can help and make it easier for you, we are always ready for that. So, let's discuss what's on your mind.</p>
                                <br /><br /><br />
                                <button className="btn btn-primary btn-circle btn-lg btn-more-arrow" onClick={event => window.location.href = 'https://wa.me/6287884911473?text=Halo'}>Chat now <i className="bi-arrow-right"></i></button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* JustTalk */}

            {/* Portfolio */}
            <div className="sectionPortfolio">
                <Container>
                    <Row className="mb-5">
                        <Col><h2>What have we done</h2></Col>
                    </Row>
                    <Carousel>
                        <Carousel.Item>
                            <ul className="listPortfolio">
                                <li>
                                    <div className="item">
                                        <img src={lintas} />
                                        <strong>PT Lintas Teknologi</strong>
                                        <p>Chat with us for more information about this project</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="item">
                                        <img src={batik} />
                                        <strong>Batik AI</strong>
                                        <p>Chat with us for more information about this project</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="item">
                                        <img src={gusami} />
                                        <strong>Gus Ami Mobile App</strong>
                                        <p>Chat with us for more information about this project</p>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>
                        <Carousel.Item>
                            <ul className="listPortfolio">
                                <li>
                                    <div className="item">
                                        <img src={eventByMe} />
                                        <strong>Event By Me</strong>
                                        <p>Chat with us for more information about this project</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="item">
                                        <img src={orangProduk} />
                                        <strong>Orang Produk StartUp</strong>
                                        <p>Chat with us for more information about this project</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="item">
                                        <img src={kudaApp} />
                                        <strong>Equinride</strong>
                                        <p>Chat with us for more information about this project</p>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>
                    </Carousel>
                </Container>
            </div>
            {/* Portfolio */}

            {/* Footer */}
            <div className="footer">
                <button className="btnWhatsappMobile" onClick={event => window.location.href = 'https://wa.me/6287884911473?text=Halo'}><i className="bi-whatsapp"></i></button>
                <Container>
                    <Row>
                        <Col md={3} sm={12}><a href="/"><img src={logo} className="logoFooter" /></a></Col>
                        <Col md={6} sm={12}><small>&copy; Copy right 2021 - Powered by Fidia - Allright reserved</small></Col>
                        <Col md={3} sm={12}></Col>
                    </Row>
                </Container>
            </div>
            {/* Footer */}

        </>
    );
}
export default Homepage;