import React, { useState, useEffect } from 'react';
import Logo from '../images/logo.svg'
import {
    Container, Navbar, Nav,Button
} from 'react-bootstrap';
// import Sidebar from "react-sidebar";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";



// Dark mode

function Mainnav() {

    // Dark mode
    var darkMode = false;
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        darkMode = true;
    }
    if (localStorage.getItem('theme') === 'dark') {
        darkMode = true;
    } else if (localStorage.getItem('theme') === 'light') {
        darkMode = false;
    }
    if (darkMode) {
        document.body.classList.toggle('dark');
    }
    document.addEventListener('DOMContentLoaded', () => {
        document.getElementById('theme-toggle').addEventListener('click', () => {
            document.body.classList.toggle('dark');
            localStorage.setItem('theme', document.body.classList.contains('dark') ? 'dark' : 'light');
        });

        document.getElementById('theme-toggle-mobile').addEventListener('click', () => {
            document.body.classList.toggle('dark');
            localStorage.setItem('theme', document.body.classList.contains('dark') ? 'dark' : 'light');
        });

    });

    const [sidebarMobile, setSidebarMobile] = useState(false);
    const ShowNav = () => <div>You clicked the button!</div>;

    const [scroll, setScroll] = useState(false);


    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);

    const [darkModeOn, setDarkMode] = useState(false);
    // const [iconDarkMode, setIconDarkMode] = useState(false);

    useEffect(() => {
        document.documentElement.setAttribute("data-dark-mode", darkModeOn);
    });

    return (
        <>

            <Navbar sticky="top" expand="lg" id="mainNavbar" className={scroll ? "mainNavbarOnScroll" : "mainNavbar mb-3"}>
                
                <Container>
                    <Navbar.Brand href="/"><img src={Logo} /></Navbar.Brand>
                    <button className="btn-transparent btn-wa btn-darkmodeMobile btn" size="lg" id="theme-toggle-mobile"><i class="bi-sun"></i></button>
                    <div variant="secondary ml-3" className="btnToggleMenu d-lg-none d-xl-none" onClick={() => setSidebarMobile(!sidebarMobile)}><i className="bi-list"></i></div>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="mr-auto my-2 my-lg-0 ml-5"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <Nav.Link href="#why">Why </Nav.Link>
                            <Nav.Link href="#who">Who</Nav.Link>
                            <Nav.Link href="#what">What</Nav.Link>
                            <Nav.Link href="#price">Price</Nav.Link>

                        </Nav>

                        {/* Nav Right */}
                        <div className="d-flex">
                            <Button className="btn-transparent btn-wa" size="lg" id="theme-toggle"><i class="bi-sun"></i></Button>
                            <Button className="btn-wa btn-light ml-3" size="lg" onClick={event =>  window.location.href='https://wa.me/6287884911473?text=Halo'}><i class="bi-whatsapp"></i></Button>
                            <Button variant="secondary ml-3" size="lg" className="btn-circle" onClick={event =>  window.location.href='https://wa.me/6287884911473?text=Halo'}>Contact us</Button>
                        </div>
                        {/* Nav Right */}

                    </Navbar.Collapse>
                </Container>

            </Navbar>

            <div className={sidebarMobile ? "openMobileNav mobileNavContainer" : "closeMobileNav mobileNavContainer"}>
                <div className="d-flex header">
                    <div><div className="logoMobile"><img src={Logo} className="logo " /></div></div>
                    <div><button onClick={() => setSidebarMobile(!sidebarMobile)}><i className="bi-x"></i></button></div>
                </div>

                <ul className="pl-3 pr-3">
                    <li><button className="btn btn-primary mb-4 btn-block btn-circle"  onClick={event =>  window.location.href='https://wa.me/6287884911473?text=Halo'}>Chat with us</button></li>
                    <li><button className="btn btn-primary btn-block btn-circle" onClick={event =>  window.location.href='tel:6287884911473'}>Call now</button></li>
                </ul>

            </div>

        </>
    );
}

export default Mainnav;